import { Field, Form, Formik } from "formik";
import { Link, navigate } from "gatsby";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { date, object, string } from "yup";
import {
  intervalToDuration,
  isDate,
  parse,
  setHours,
  setMinutes,
} from "date-fns";

import Cart from "../components/Cart";
import { CartContext } from "../cartContext";
import { FormContext } from "../formContext";
import PaypalButton from "../components/PaypalButton";

const getInvoiceNumber = async () => {
  return new Promise((resolve, reject) => {
    var axios = require("axios");
    var config = {
      method: "get",
      // url: "http://localhost:5000/api",
      url: " https://creperie-mobile.herokuapp.com/getinvoicenumber",
      // url: "https://invoicepunschtaxi.herokuapp.com/api",
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        const { data, error } = response;
        if (error) {
          reject(error);
        } else {
          resolve(data?.invoice);
        }
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

function Checkout03() {
  const { countCart, clearCart, orderMini, cart, hasBox } =
    useContext(CartContext);
  let initialValues = {
    fullname: "",
    email: "",
    phone: "",
    deliverydate: "",
    deliverytime: "",
    deliverytimeEnd: "",
    stiege: "",
    tur: "",
    stock: "",
    cart: "",
    formattedCart: "",
    paymentID: "",
    distance: 0,
    single: 0,
    double: 0,
    address: "",
    lat: 0,
    lng: 0,
    codepromo: "",
    firma: false,
  };
  const [loading, setLoading] = useState(false);
  const { checkout, saveForm } = useContext(FormContext);
  const [sForm, setsForm] = useState(initialValues);
  const [invoiceNumber, setInvoiceNumber] = useState(0);

  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  function parseDateString(value, originalValue) {
    const parsedDate = isDate(originalValue)
      ? originalValue
      : parse(originalValue, "yyyy-MM-dd", new Date());
    return parsedDate;
  }
  function parseTimeString(value) {
    const parsedTime = value.split(":", 2);
    const _pt = Number(parsedTime[0]);
    return _pt;
  }
  function parseTimeMinuteString(value) {
    const parsedTime = value.split(":", 2);
    const _pt = Number(parsedTime[1]);
    return _pt;
  }
  const thisday = new Date();
  const day = thisday.getDate();
  const month = thisday.getMonth();
  const year = thisday.getFullYear();

  const today = new Date(year, month, day, 0, 0, 0);
  const subscriptionSchema = object().shape({
    fullname: string()
      .required("Bitte Namen ausfüllen")
      .min(2, "Bitte Namen ausfüllen"),

    email: string()
      .required("Angabe Email wird benötigt")
      .email("Bitte gültige Email eingeben")
      .min(6, "Email muß aus mind. 6 Zeichen bestehen")
      .max(100, "Email darf aus max. 100 Zeichen bestehen"),
    phone: string()
      .required("Angabe Telefon-Nr. wird benötigt")
      .min(8, "Telefon-Nr. Muß aus mind. 8 Zahlen bestehen")
      .matches(phoneRegExp, "Telefon-Nr. Ist nicht gültig"),
    deliverydate: date()
      .transform(parseDateString)
      .required("Bitte Zustelldatum auswählen")
      .min(today, "Zustelldatum zu früh"),
    deliverytime: string()
      .test(
        "is-tooearly",
        "Lieferung zwischen 11 und 23",
        (value) => parseTimeString(value) >= 11 && parseTimeString(value) <= 23
      )
      .required("Bitte Zeitraum für Zustellung auswählen")
      .min(5, "ungültige Uhrzeit"),
    deliverytimeEnd: string()
      .test(
        "is-tooearly",
        "Lieferung zwischen 11 und 23",
        (value) => parseTimeString(value) >= 11 && parseTimeString(value) <= 23
      )
      .test("is-notlong", "Give us 2 hours range", (value) => {
        let _start = new Date();
        _start = setHours(_start, parseTimeString(sForm["deliverytime"]));
        _start = setMinutes(
          _start,
          parseTimeMinuteString(sForm["deliverytime"])
        );
        let _end = new Date();
        _end = setHours(_end, parseTimeString(value));
        _end = setMinutes(_end, parseTimeMinuteString(value));
        const interval = intervalToDuration({ start: _start, end: _end });
        if (interval.hours >= 2) {
          return true;
        } else {
          return false;
        }
      })
      .required("Bitte Zeitraum für Zustellung auswählen")
      .min(5, "ungültige Uhrzeit"),
  });
  const totalCart = countCart().totalCart;
  const deliveryFrom = orderMini ? orderMini : 0;
  const deliveryTotal = countCart().finalFees ? countCart().finalFees : 0;

  let orderTotal =
    deliveryFrom > totalCart ? deliveryFrom : countCart().totalToPay;
  let discount =
    checkout[0]?.codepromo === "PT0512"
      ? ((orderTotal * 20) / 100).toFixed(0)
      : 0;
  let gutschein30 = checkout[0]?.codepromo === "FG54T3" ? 30 : 0;
  let gutschein50 = checkout[0]?.codepromo === "FG55U3" ? 50 : 0;
  let gutschein70 = checkout[0]?.codepromo === "FG57V3" ? 70 : 0;
  let gutschein100 = checkout[0]?.codepromo === "FG5493" ? 100 : 0;
  const influencer =
    checkout[0]?.codepromo === "AD22RT" ? Number(orderTotal).toFixed(0) : 0;
  orderTotal =
    orderTotal -
    discount -
    gutschein30 -
    gutschein50 -
    gutschein70 -
    gutschein100 -
    influencer;
  if (orderTotal < 0) orderTotal = 0;
  const formatCartPaypal = () => {
    const currency = "EUR";
    const note = `Lieferung am: ${sForm.deliverydate} swischen ${sForm.deliverytime} & ${sForm.deliverytimeEnd}`;
    let total = orderTotal;

    const taxAmount = 1;
    let subTotal =
      total -
      deliveryTotal +
      Number(discount) +
      Number(gutschein30) +
      Number(gutschein50) +
      Number(gutschein70) +
      Number(gutschein100);
    let shipping = deliveryTotal;
    let fullname = sForm["fullname"];
    const addressLine1 = `${sForm.street} ${sForm.streetNumber} ${sForm.stiege} ${sForm.tur} ${sForm.stock}`;
    const stiege = sForm.stiege;
    const tur = sForm.tur;
    const stock = sForm.stock;
    const addressLine2 = `sw. ${sForm.deliverytime} & ${sForm.deliverytimeEnd}`;
    const city = sForm.city;
    const zip = sForm.zip;
    const phone = sForm.phone;

    const distance = sForm.distance;
    const duration = sForm.duration;
    const hasBox = sForm.hasBox;

    const amount = {
      total: total,
      currency: currency,
      details: {
        subtotal: subTotal,
        tax: 0,
        shipping: shipping,
        handling_fee: 0,
        shipping_discount: Number(discount),
        discount:
          Number(gutschein30) +
          Number(gutschein50) +
          Number(gutschein70) +
          Number(gutschein100),
        insurance: 0,
      },
    };

    const shippingAddress = {
      recipient_name: fullname,
      line1: addressLine1,
      line2: addressLine2,
      city: city,
      country_code: "AT",
      postal_code: zip,
      phone: phone,
      state: "",
      // stiege: stiege,
      // tur: tur,
      // stock: stock,
      // distance: distance,
      // duration: duration,
      // hasBox: hasBox,
    };
    // console.log(hasBox());
    const items = [];
    cart.map((e, i) => {
      //console.log(e);
      let TAX = e.price * taxAmount;
      TAX = parseFloat(TAX.toFixed(2));
      const item = {
        name: e.name,
        // category: e.categoryName,
        description: e.formattedProduct,
        quantity: e.amount,
        price: e.price,
        sku: e.id,
        currency: currency,
      };
      items.push(item);
    });
    const itemList = { items: items, shipping_address: shippingAddress };
    const transactions = [
      {
        amount: amount,
        invoice_number: invoiceNumber,
        item_list: itemList,
      },
    ];
    const _paymentOptions = {
      transactions: transactions,
      note_to_payer: note,
      redirect_urls: {
        return_url: "https://punsch-mobil.at/success",
        cancel_url: "https://punsch-mobil.at/cancel",
      },
    };
    return _paymentOptions;
  };
  const formatCart = () => {
    const currency = "EUR";
    const note = `Lieferung am: ${sForm.deliverydate} swischen ${sForm.deliverytime} & ${sForm.deliverytimeEnd}`;
    let total = orderTotal;
    const taxAmount = 1;
    let subTotal = total - deliveryTotal + Number(discount);
    let shipping = deliveryTotal;
    let fullname = sForm["fullname"];
    const addressLine1 = `${sForm.street} ${sForm.streetNumber} ${sForm.stiege} ${sForm.tur} ${sForm.stock}`;
    const stiege = sForm.stiege;
    const tur = sForm.tur;
    const stock = sForm.stock;
    const addressLine2 = `sw. ${sForm.deliverytime} & ${sForm.deliverytimeEnd}`;
    const city = sForm.city;
    const zip = sForm.zip;
    const phone = sForm.phone;
    const d = new Date();

    const distance = sForm.distance;
    const duration = sForm.duration;
    const hasBox = sForm.hasBox;

    const amount = {
      total: total,
      currency: currency,
      details: {
        subtotal: subTotal,
        tax: 0,
        shipping: shipping,
        handling_fee: 0,
        discount:
          Number(gutschein30) +
          Number(gutschein50) +
          Number(gutschein70) +
          Number(gutschein100),
        shipping_discount: Number(discount),
        insurance: 0,
      },
    };
    const shippingAddress = {
      recipient_name: fullname,
      line1: addressLine1,
      line2: addressLine2,
      city: city,
      country_code: "AT",
      postal_code: zip,
      phone: phone,
      state: "",
      stiege: stiege,
      tur: tur,
      stock: stock,
      distance: distance,
      duration: duration,
      hasBox: hasBox,
    };
    // console.log(hasBox());
    const items = [];

    cart.map((e, i) => {
      //console.log(e);
      let TAX = e.price * taxAmount;
      TAX = parseFloat(TAX.toFixed(2));
      const item = {
        name: e.name,
        category: e.categoryName,
        description: e.formattedProduct,
        quantity: e.amount,
        price: e.price,
        sku: e.id,
        currency: currency,
      };
      items.push(item);
    });
    const itemList = { items: items, shipping_address: shippingAddress };
    const transactions = [
      {
        amount: amount,
        invoice_number: invoiceNumber,
        item_list: itemList,
      },
    ];
    const _paymentOptions = {
      transactions: transactions,
      note_to_payer: note,
      redirect_urls: {
        return_url: "https://punsch-mobil.at/success",
        cancel_url: "https://punsch-mobil.at/cancel",
      },
    };
    return _paymentOptions;
  };

  let paymentOptions = formatCartPaypal();

  const sendInvoice = async (payment, form) => {
    form.hasBox = hasBox();
    form.firma = sForm.firma;
    return new Promise((resolve, reject) => {
      var axios = require("axios");
      let _paymentOptions = formatCart();
      const data = JSON.stringify({
        invoice: _paymentOptions,
        paypal: payment,
        delivery: form,
      });
      var config = {
        method: "post",
        // url: "http://localhost:5000/api",
        url: " https://creperie-mobile.herokuapp.com/api",
        // url: "https://invoicepunschtaxi.herokuapp.com/api",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          resolve(JSON.stringify(response.data));
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  const totalNullAfterPromo = () => {
    setLoading(true);
    const payment = { payerID: "", email: "", paymentID: "" };
    console.log("No payment required, sending confirmations");
    sendInvoice(payment, sForm)
      .then((res) => {
        sForm.codepromo = "";
        clearCart();
        setLoading(false);
        navigate("/success");
      })
      .catch((err) => {
        sForm.codepromo = "";
        clearCart();
        setLoading(false);
        navigate("/success");
      });
  };
  const handleSuccess = (payment) => {
    console.log("payment success");

    sendInvoice(payment, sForm)
      .then((res) => {
        clearCart();
        sForm.codepromo = "";
        setLoading(false);
        navigate("/success");
      })
      .catch((err) => {
        console.log("inside catch");
        console.log(err);
        clearCart();
        sForm.codepromo = "";
        setLoading(false);
        navigate("/success");
      });
  };

  const handleCancel = () => {
    setLoading(false);
    console.log("error during payment");
    alert("Error during Payment");
  };

  useEffect(() => {
    if (checkout.length > 0) {
      getInvoiceNumber().then((invoice) => {
        console.log(invoice);
        setInvoiceNumber(invoice);
        setsForm(checkout[0]);
        paymentOptions = formatCartPaypal();
      });
    }

    return () => {};
  }, [checkout]);

  useEffect(() => {
    if (cart.length === 0) sForm.codepromo = "";
  }, [cart]);

  useEffect(() => {
    console.log(cart);
  }, []);
  return (
    <Fragment>
      <Formik
        initialValues={sForm}
        enableReinitialize
        mapPropsToErrors
        validationSchema={subscriptionSchema}
        onSubmit={(values) => {}}
      >
        {(formik, isSubmitting) => {
          return (
            <Form
              name="checkout"
              className="needs-validation"
              netlify="true"
              netlify-honeypot="bot-field"
            >
              <div className="page-title-overlap bg-accent pt-2">
                <div className="container d-lg-flex justify-content-between py-4 py-lg-3">
                  <div className="pl-lg-2 order-lg-2 mb-0 mb-lg-0 pt-lg-2 breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center">
                    <p className="text-white">
                      <Link to="/Checkout01" alt="checkout">
                        CHECKOUT
                      </Link>
                      {" > "}{" "}
                      <Link to="/Checkout02" alt="checkout">
                        KUNDENNINFO
                      </Link>
                      {" > "} ZAHLUNG
                    </p>
                  </div>
                </div>
              </div>
              <section className="container p-0 p-sm-4">
                <div className="row no-gutters py-md-1 py-lg-4 px-2 px-sm-2 px-md-3 px-lg-4">
                  <div className="col-xl-6 mb-3 bg-light box-shadow-lg rounded-lg overflow-hidden px-3 py-3 p-sm-3">
                    <h5 className="mx-0 mt-1 border-bottom">Zahlung</h5>
                    <p>Bitte überprüfen Sie die folgenden Informationen:</p>
                    <div className="">
                      <table>
                        <tbody>
                          <tr className="">
                            <th className="text-left font-weight-light">
                              Name
                            </th>
                            <th className="text-left font-weight-medium">
                              {sForm["fullname"]}
                            </th>
                          </tr>
                          <tr className="">
                            <th className="text-left font-weight-light">
                              Address
                            </th>
                            <th className="text-left font-weight-medium">
                              {sForm["address"]}
                            </th>
                          </tr>

                          <tr className="">
                            <th className="text-left font-weight-light">Tür</th>
                            <th className="text-left font-weight-medium">{`${sForm["tur"]}`}</th>
                          </tr>

                          <tr className="">
                            <th className="text-left font-weight-light">
                              Stiege
                            </th>
                            <th className="text-left font-weight-medium">{`${sForm["stiege"]}`}</th>
                          </tr>

                          <tr className="">
                            <th className="text-left font-weight-light">
                              Stock
                            </th>
                            <th className="text-left font-weight-medium">{`${sForm["stock"]}`}</th>
                          </tr>

                          <tr className="">
                            <th className="text-left font-weight-light">
                              Lieferdatum
                            </th>
                            <th className="text-left font-weight-medium">{`${sForm["deliverydate"]}`}</th>
                          </tr>
                          <tr className="">
                            <th className="text-left font-weight-light">
                              Lieferzeit
                            </th>
                            <th className="text-left font-weight-medium">{`${sForm["deliverytime"]} - ${sForm["deliverytimeEnd"]}`}</th>
                          </tr>
                          <tr className="">
                            <th className="text-left font-weight-light">
                              Summe
                            </th>
                            <th className="text-left font-weight-medium">
                              {orderTotal} €
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {sForm.firma === true && orderTotal > 0 && (
                      <div className="w-100 alert text-center mt-4">
                        <button
                          type="button"
                          className="btn btn-danger"
                          disabled={loading}
                          onClick={totalNullAfterPromo}
                        >
                          Bezahlung per Bank-Überweisung
                        </button>
                        <div className="mt-4">or</div>
                      </div>
                    )}
                    {cart.length !== 0 ? (
                      totalCart !== 0 && orderTotal > 0 ? (
                        <div className="w-100 alert alert-info text-center mt-3">
                          <img
                            src="../../paypalvis.png"
                            width="125px"
                            alt="logo-paypal"
                          />
                          <p className="pt-3">
                            Klicken Sie auf Paypal Checkout, um Ihre Bestellung
                            zu bezahlen
                            <br />
                            <span className="font-size-s">
                              (Ein Paypal-Konto ist nicht erforderlich)
                            </span>
                          </p>

                          {paymentOptions &&
                            (!loading ? (
                              <PaypalButton
                                totalAmount={orderTotal}
                                handleSuccess={handleSuccess}
                                handleCancel={handleCancel}
                                paymentOptions={paymentOptions}
                                onClick={() => setLoading(true)}
                              ></PaypalButton>
                            ) : (
                              <div
                                className="spinner-border text-danger"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ))}
                          {/* DEV ONLY */}
                          {/* <button onClick={() => handleSuccess({})} /> */}
                        </div>
                      ) : (
                        <div className="w-100 alert text-center mt-4">
                          {!loading ? (
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={totalNullAfterPromo}
                              disabled={loading}
                            >
                              Bestätigen Sie Ihre Bestellung
                            </button>
                          ) : (
                            <div
                              className="spinner-border text-danger"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          )}
                        </div>
                      )
                    ) : null}
                  </div>

                  <div className="col-xl-5 px-sm-2 offset-xl-1 ">
                    <Cart
                      single={checkout["single"]}
                      double={checkout["double"]}
                    />
                  </div>
                </div>
              </section>
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
}

export default Checkout03;
